import * as _ from 'lodash'
import { safelyStringify, serializeError } from '../../utils/utils'
import * as formsApi from './forms-api.json'

export const wrapPublicApi = (
  f,
  funcName,
  ravenInstance,
  { absorbException = true, includeArgs = true } = {}
): Function => {
  return (...args) => {
    const stringifiedArgs = safelyStringify(args)
    ravenInstance.captureBreadcrumb({
      message: `[core-api] '${funcName}'`,
      category: 'core-api',
      data: {
        name: funcName,
        ...(includeArgs ? { args: stringifiedArgs } : {}),
      },
      level: 'info',
    })
    const handleError = err => {
      console.error(err)
      ravenInstance.captureException(err, {
        tags: { 'core-api': funcName },
        extra: {
          args: stringifiedArgs,
          error: serializeError(err),
        },
      })
      if (absorbException) {
        return null
      } else {
        throw err
      }
    }
    try {
      const funcResult = f(...args)
      if (funcResult instanceof Promise) {
        return funcResult.catch(handleError)
      } else {
        return funcResult
      }
    } catch (err) {
      return handleError(err)
    }
  }
}

const generateRuntimeApi = (
  obj,
  startObject,
  ravenInstance,
  funcNameTransformer = funcName => funcName
) => {
  return _.reduce(
    Object.getOwnPropertyNames(Object.getPrototypeOf(obj)),
    (apiObj, funcName) => {
      const f = obj[funcName].bind(obj)
      return !_.startsWith(funcName, '_') // expose only public methods
        ? _.merge(apiObj, {
            [funcNameTransformer(funcName)]: wrapPublicApi(f, funcName, ravenInstance),
          })
        : apiObj
    },
    startObject
  )
}

const generateRuntimeApis = (apis, ravenInstance) => {
  const toPublicApi = apiName => {
    const funcNameTransformer = funcName => `${apiName}.${funcName}`
    return generateRuntimeApi(apis[apiName], {}, ravenInstance, funcNameTransformer)
  }

  const apisNames = Object.keys(apis)
  const apisFunctions = apisNames.map(toPublicApi)

  return _.assign({}, ...apisFunctions)
}

export const generateRuntimeCoreApi = (coreApi, apis, ravenInstance) => {
  const runtimeApis = generateRuntimeApis(apis, ravenInstance)
  return generateRuntimeApi(coreApi, runtimeApis, ravenInstance)
}

export const generateExportedApi = (editorAppMetaData, ravenInstance) => {
  const createApiFunction = path => async (...payload) => {
    const api = await editorAppMetaData.getCoreApi()
    const apiFunction = path.reduce((acc, p) => {
      if (_.isFunction(acc[p])) {
        return acc[p].bind(acc)
      }
      return acc[p]
    }, api)
    return apiFunction(...payload)
  }
  const apiPaths = getAllPaths(formsApi)
  return apiPaths.reduce((acc, path) => {
    const pathKey = path.join('_')
    const f = createApiFunction(path)
    acc[pathKey] = ravenInstance.wrap(
      wrapPublicApi(f, pathKey, ravenInstance, { absorbException: false, includeArgs: false })
    )
    return acc
  }, {})
}

const getAllPaths = api => {
  let paths = []
  const walk = (obj, path) => {
    path = path || []
    for (let n in obj) {
      if (!obj.hasOwnProperty(n) || n == 'default') continue
      if (typeof obj[n] === 'object') {
        walk(obj[n], path.concat(n))
      } else {
        paths.push(path.concat(n))
      }
    }
  }
  walk(api, [])
  return paths
}
